<template>
    <Header title="Event" />
    <div class="top_margin"></div>
    <section class="row_padding">
        <main class="header-offset content-wrapper about-wrapper">
            <div class="terms-container">
                <div class="row">
                    <div class="col-sm-8 col-sm-offset-2">
                        <section class="terms-title">
                            <h1>Rio Hill</h1>
                        </section>
                        <section class="terms-title">
                            <h1>Terms and Conditions</h1>
                            <hr>
                        </section>

                        <div class="terms-body">
                            <h3>1. ARRIVAL AND DEPARTURE TIMES</h3>
                            <p>
                                The designated check in time is 3:00pm, with a check out time of 12:00pm, unless other
                                arrangements have been made and agreed upon by the Lessor. Any early arrivals or late
                                departure times are subject to the availability of the villa, and will impose extra
                                fees.
                            </p>
                            <hr>
                            <h3>2. REGISTERED GUESTS</h3>
                            <p>
                                Only the specified number of persons listed in the Rental Agreement at the time of booking
                                may
                                reside on the premises. The number of registered guests given at the time of the booking is
                                the
                                maximum number of persons allowed. If the Lessor or a representative of the Lessor will find
                                out
                                that an additional number of guests are staying at the property, it is the absolute
                                discretion
                                of the Lessor or the representative to ask the extra person or persons to vacate the
                                premises.
                                Otherwise, each additional person will be charged a published rate of per night.
                            </p>
                            <hr>
                            <h3>3. MINIMUM STAY REQUIREMENTS</h3>
                            <p>
                                There is a minimum 3 days stay requirement for most of the year. During peak seasons
                                (Christmas
                                & New Year), the minimum stay is from 7 to 10 days. For holidays such as Easter and Chinese
                                New
                                Year, the minimum stay is 5 days. This may not be applicable to some villas.
                            </p>
                            <hr>
                            <h3>4. WEDDINGS & EVENTS</h3>
                            <p>
                                Some villas may or may not allow weddings on the premises. If you take it upon yourself to
                                host
                                additional visitors while staying at the villa, you will be held responsible for any
                                additional
                                costs associated with cleaning, if necessary.
                            </p>
                            <hr>
                            <h3>5. PAYMENT REQUIREMENTS</h3>
                            <p>
                                When booking the villa less than six months in advance, within 5 days of booking, a minimum
                                deposit of 50% will be required. This deposit is non-refundable. If you are booking the
                                villa
                                more than six months in advance, a 25% deposit is due within 5 calendar days of booking. The
                                additional 25% of the deposit will be requested 6 months prior to your stay. Payment is due
                                in
                                full 45 days before your expected arrival date. No contract will exist between the guest and
                                villa owner until receipt of funds. If funds are not received within 5 banking days the
                                reservation shall be deemed to have been canceled and the owner reserves the right to
                                allocate
                                the Villa to another client.
                            </p>
                            <hr>
                            <h3>6. SECURITY DEPOSIT</h3>
                            <p>
                                A security deposit is required which will be requested by the Lessor 10 days prior to your
                                arrival. The security deposit can be paid via PayPal, or bank transfer. If payment in full
                                has
                                been received prior to the date of your arrival, this security deposit will be returned to
                                the
                                Lessee within 48 hours after check out. In the event that the villa is damaged or any
                                breakages
                                occur during the rental period including both inside and outside, the costs will be deducted
                                from this security deposit. This excludes normal and acceptable wear and tear. The condition
                                of
                                the property will be judged at the sole discretion of the owner of the property or the
                                owner’s
                                representative. If damages were to exceed the amount of the security deposit, the Lessee
                                will be
                                held liable to pay the outstanding amount to the Lessor as soon as possible.
                            </p>
                            <hr>
                            <h3>7. CANCELLATION POLICIES</h3>
                            <p>
                                If it becomes necessary to cancel all or any part of the booking, Rio Colina must be
                                notified in
                                writing (email acceptable). The cancellation will take effect from the day the written
                                confirmation is received. The following cancellation charges will be applicable depending on
                                when the notification of the cancellation is received in writing and the period of stay in
                                the
                                Villa.
                            </p>
                            <hr>
                            <p>
                                All cancellations made by the Lessee are subject to penalties below:
                            </p>
                            <hr>
                            <p>
                                A) If the Lessee cancels the reservation 45 days or more prior to arrival date, all payments
                                are
                                forfeited but credited for 9 months from the date of the cancellation confirmation by the
                                Lessor. The credit value can be used by the Lessee or anyone on his or her behalf for any
                                reservation, during any period upon availability and solely for the villa initially
                                reserved.
                            </p>
                            <hr>
                            <p>
                                B) If the Lessee cancels the reservation within 45 days prior to arrival date, all rental
                                payments are forfeited. A full or partial refund is solely applicable in the event that the
                                Lessor secures another reservation covering partially or fully the initial canceled
                                reservation
                            </p>
                            <hr>
                            <p>
                                C) Christmas and New Year Period, for any cancellation after the deposit and/or balance
                                payment,
                                all payments are forfeited. However, a full or partial refund is solely applicable in the
                                event
                                that the Lessor secures another reservation covering partially or fully the initial and
                                canceled
                                reservation.
                            </p>
                            <hr>
                            <p>
                                The above cancellation charges also apply if the Lessor cancels the booking due to
                                non-payment
                                of the balance.
                            </p>
                            <hr>
                            <h3>8. ALTERATIONS AND CANCELLATION BY THE VILLA OWNER</h3>
                            <p>
                                Though it is unlikely that the villa owner will have to make any changes to confirmed
                                arrangements, it does occasionally happen, and we will advise you at the earliest possible
                                date.
                                If, for any reason beyond our control, we are unable to provide you with the Villa booked,
                                the
                                villa owner shall reserve the right to cancel the reservation. For example, but not
                                inclusive,
                                if the properties are damaged or rendered unusable the villa owner will endeavor to locate
                                the
                                Guests to an alternative property for the period required but no guarantee is given that
                                this
                                can be done. However, if this is not possible, or Guests do not wish to be transferred, the
                                villa owner will cancel the booking and refund in full, less any bank transfer charges, the
                                amount paid to the villa owner for the Villa. The villa owner shall not be liable for any
                                further obligations or claims by the client.
                            </p>
                            <hr>
                            <h3>9. CHANGES OF RESERVATIONS</h3>
                            <p>
                                Any changes made to the reservation dates after confirmation are considered to be a breach
                                of
                                agreement and is considered to be a forfeit of the rental agreement. Any down payment and
                                other
                                payments are subject to the cancellation conditions stated in article 7 of these terms and
                                conditions.
                            </p>
                            <hr>
                            <h3>10. LESSEE INSURANCE</h3>
                            <p>
                                Guests are required to have both liability and comprehensive travel insurance that provides
                                coverage including, but not limited to, cancellation, loss and damage to baggage and other
                                property, and flight delays. Guests should also carry health coverage that includes, but is
                                not
                                limited to evacuation and repatriation. The Luxury Signature or the owner is not responsible
                                to
                                you and your party for any and all claims including any accidents related to the use of the
                                property facilities or locally procured third party services such as, watercraft, water
                                sports,
                                jeep or motorbike rental etc
                            </p>
                            <hr>
                            <h3>11. FORCE MAJEURE</h3>
                            <p>
                                In the event of such incidents as fires, floods, civil disturbances, severe weather, civil
                                disturbances affecting the destination, Acts of God, and any other type of delay,
                                inconvenience,
                                or expenses caused directly or indirectly by events outside of the Lessor’s control, the
                                Lessor
                                will not be held responsible.
                            </p>
                            <hr>
                            <h3>12. COMPLAINTS</h3>
                            <p>
                                Any problems or complaints that occur during the rental period should first be discussed
                                with
                                the local staff. If the staff is unable to quickly resolve the issue, please consult the
                                Concierge or Rio Colina, who will rectify the situation to the best of their professional
                                ability. The Lessor is not responsible for such issues as interruptions to water supply,
                                electricity, Internet connection, cable TV, or breakdowns of lifts or pool filtration
                                systems.
                                The Lessor will do everything within reasonable expectation to avoid complaints in the first
                                place, and it is understood that when staying in a less developed remote resort location,
                                that
                                infrastructure, local standards, and conditions are typically less developed than in urban
                                environments. Any complaints should be written and given to the Lessor within 24 hours of
                                the
                                time the issue occurred.

                            </p>
                            <hr>
                            <h3>13. CONDUCT BEHAVIOR</h3>
                            <p>
                                When renting the villa, the Lessor maintains responsibility for the appropriate behavior of
                                the
                                guests. If any guest behaves in an inappropriate manner, the Lessor or their representative,
                                at
                                their absolute discretion, may ask the offending party to leave the premises. In this case,
                                no
                                refund may be claimed by the Lessor. In addition, the owner of the villa or representatives
                                of
                                the owner reserve the right to enter the premises at a reasonable time in order to gain
                                access
                                to complete repairs, perform maintenance, or to show the villa to prospective Lessee.
                            </p>
                            <hr>
                            <h3>14. SOCIAL CORPORATE RESPONSIBILITY</h3>
                            <p>
                                Bringing any illegal substances into the villa is prohibited, along with the use or
                                consumption
                                of any illegal substances. Unlawful behavior is also prohibited. The Police will be
                                immediately
                                contacted if there is any violation. For the safety of our guests and local employees, as
                                well
                                as for protection of property, guests may not bring outside guests back to the villa.
                            </p>
                            <hr>
                            <h3>15. VILLA STAFF</h3>
                            <p>
                                Staff service is included in your stay at the villa. However, at your own discretion, a tip
                                may
                                be given upon departure. Additional services such as babysitting and drivers can be arranged
                                in
                                advance and are subject to availability. Asking staff to look after minor children is not
                                allowed. The Lessee must allow staff such as pool maintenance workers and gardeners access
                                to
                                the grounds in order to complete their work.
                            </p>
                            <hr>
                            <h3>16. NO PETS</h3>
                            <p>
                                There is a ‘No Pet Policy” in the villa and on surrounding properties. This may or may not
                                be
                                applicable to some villas.
                            </p>
                            <hr>
                            <h3>17. NO SMOKING
                            </h3>
                            <p>
                                There is no smoking permitted in the villa. Smoking must be done outside on the grounds,
                                where
                                an ash tray can be provided by the Villa Manager.
                            </p>
                            <hr>
                            <h3>18. NOISE</h3>
                            <p>
                                Noise levels must be kept at a reasonable minimum, especially during the hours of 11pm and
                                8am
                                while guests are sleeping.
                            </p>
                            <hr>
                            <h3>19. FOOD AND BEVERAGES</h3>
                            <p>
                                All rentals include a Chef Service for Breakfast, Lunch, Dinner and Snacks. All food and
                                beverages purchased on behalf of the guests are charged at a price of cost plus a 10-25%
                                surcharge (depending on the villa) that covers food preparation, transportation, time
                                involved
                                in the shopping and cooking process, and the use of the kitchen. Bills for food and drinks
                                are
                                expected to be cleared periodically and before departure.
                            </p>
                            <hr>
                            <h3>20. LINENS AND TOWELS</h3>
                            <p>
                                Linens and towels are provided by the villa and due to our eco-friendly Save the Planet
                                policy,
                                linens are changed every third day, more often if necessary. Towels are replaced after
                                placement
                                in the towel basket. Additional charges may apply if more frequent changes are needed. Items
                                such as bedding, washing, or clothing articles should not be hung where it is visible to
                                another
                                residence or common property on the estate. Laundry of personal clothing by a third party
                                can be
                                arranged at the lessee's cost, the Lessor cannot take responsibility for any loss or damage
                                by
                                the third party.
                            </p>
                            <hr>
                            <h3>21. TRANSFERS</h3>
                            <p>
                                Rates include airport pick up and return. Any additional transfers may be arranged through
                                the
                                Villa Concierge with applicable fees.
                            </p>
                            <hr>
                            <h3>22. UTILITIES</h3>
                            <p>
                                Utility costs are included with the rent. Windows must be closed while air-conditioning is
                                in
                                use. Rio Colina abides with an eco-friendly policy. Therefore, we request that all
                                air-conditioning must be switched off when you need to go out or stay long outside of the
                                villa,
                                villa staff may comply with this policy.
                            </p>
                            <hr>
                            <h3>23. FURNITURE</h3>
                            <p>
                                Interior furnishings must remain inside the villa, and only designated outdoor furniture can
                                be
                                used on the exterior.
                            </p>
                            <hr>
                            <h3>24. LESSOR INSURANCE</h3>
                            <p>
                                The Lessee must not do, or allow anything to be done that may cause the villa’s insurance
                                against loss or damage by fire to become void or cause insurance premiums to be raised.
                            </p>
                            <hr>
                            <h3>25. DUE CARE AND SUPERVISION/INDEMNITY</h3>
                            <p>
                                It is understood that the Lessee is responsible and liable for the safety and well being of
                                guests and third parties while staying at the Villa. Both the Lessee and guests are required
                                to
                                take due care during their stay and take precautions such as supervision of children in the
                                gardens, near the entrance, and near or in the pool. All children must be under the direct
                                supervision of an adult at all times. No guest may enter the villa while wet from swimming,
                                as
                                floors may become slippery. Damages or injuries resulting from the above mentioned scenarios
                                are
                                the responsibility of the Lessee. The Lessee indemnifies and holds the Lessor harmless and
                                free
                                of liability resulting from such claims that result as consequences of accidents leading to
                                injury or loss of life.
                            </p>
                            <hr>
                            <h3>26. VALUABLES</h3>
                            <p>
                                Valuable items such as passports, cell phones, cameras, traveler checks, cash or jewelry
                                should
                                be stored in a safety deposit box provided in the villa. Neither the Lessor nor the staff is
                                responsible for any valuables left behind, lost, or damaged.
                            </p>
                            <hr>
                            <h3>27. ARTWORK</h3>
                            <p>
                                Guests must be aware that each villa contains precious contemporary and antique pieces of
                                art
                                unique to that villa, and must take care to avoid causing any damage to them. Any artwork or
                                antiques damaged during the rental period will be the responsibility of the Lessee and the
                                costs
                                set off against the aforementioned security deposit. If damages exceed the security deposit,
                                the
                                Lessee is liable for the remaining damages and must compensate the Lessor for the difference
                                in
                                cost.
                            </p>
                            <hr>
                            <h3>28. JURISDICTION</h3>
                            <p>
                                Any contract that is made will be with the acceptance by both parties of these terms and
                                conditions, which are governed by the local law of the property rented and both parties will
                                submit to the exclusive jurisdiction of the local courts of the property rented.
                            </p>


                            <!-- FOOTER -->
                            <div class="container terms_footer">
                                <h3></h3>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- /.row -->
            <!-- /.container -->
        </main>
    </section>
    <Footer />
</template>

<script>
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';

export default {
    name: 'TermsCondition',
    components: { Header, Footer },
    mounted() {
        let recaptchaScript2 = document.createElement("script");
        recaptchaScript2.setAttribute(
            "src",
            "./js/slick/slick.js"
        );
        document.head.appendChild(recaptchaScript2);
        let recaptchaScript3 = document.createElement("script");
        recaptchaScript3.setAttribute(
            "src",
            "./js/slick/slick.min.js"
        );
        document.head.appendChild(recaptchaScript3);
        const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "./js/script.js"
        );
        document.head.appendChild(recaptchaScript);

        window.scrollTo({ top: 0, behavior: "smooth" });
    }
}
</script>

<style scoped>.terms-container {
    font-family: 'Open Sans', sans-serif;
    color: #262626;
}

.terms-title h1 {
    font-size: 25px;
    font-family: "Itim";
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 20px;
}

.terms-body h4 {
    color: #EB573D;
    font-size: 14px;
    line-height: 25px;
}

.terms-body h3 {
    font-family: "Itim";
    color: #262626;
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
    font-size: 19px;
}

.terms-body p {
    font-size: 19px;
    line-height: 25px;
}

.terms-body a {
    color: #EB573D;
}

.terms-body a:hover {
    color: #EB573D;
    text-decoration: none;
}

.terms_footer h3 {
    margin-top: 60px;
    margin-bottom: 125px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 1.5;
    color: #262626;
}

.terms_footer h3 a:link {
    color: #EB573D;
    text-decoration: none;
}

img {
    width: 100%;
    height: 100%;
}

hr {
    clear: both;
    visibility: hidden;
}</style>